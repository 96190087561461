export default [
  {
    title: 'My Subjects',
    icon: 'BookOpenIcon',
    route: 'TeacherSubjects',
    acl: {
      roles: ['teacher'],
    },
  },
]
