<template>
  <p class="clearfix mb-0 no-print">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Licensed to
      <b-link
        class="ml-25"
        href="#"
      >Name of School</b-link>
    </span>

    <span class="float-md-right d-none d-md-block">Powered By: Company Name, © {{ new Date().getFullYear() }}
      <!-- <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      /> -->
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
