export default [
  {
    title: 'My Class',
    icon: 'UsersIcon',
    route: 'TeacherClasses',
    acl: {
      roles: ['teacher'],
    },
  },
]
